import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

//Context
import { AppContext } from "@vahak/core/dist/app-context";

//Services
import { useUserActionValidationService } from "../services/useUserActionValidationService";

//Constants
import { UserAction, UserActionRequiredResponse } from "@vahak/core/dist/constants/userAction";
import { BID_ACTIONS, BiddingFormContext } from "../BiddingFormContextProvider";
import { toast } from "@vahak/core/dist/components/toast/toast";

const useValidateUserStatus = () => {
    const {
        isPhoneVerified,
        setShowClearDuePopup,
        setShowMandateKycPopup,
        setShowGstVerificationPopup,
        setShowUpgradeMembershipPlanPopup,
        mandateUserVerificationCallBackFunction,
        setMandateUserVerificationCallBackFunction,
        setMandateUserVerificationActionTriggered,
        setMandateUserVerificationActionType,
        companyId,
        customerCompanyId
    } = useContext(AppContext);

    const { dispatchBid } = useContext(BiddingFormContext);

    const router = useRouter();

    const [enable, setEnable] = useState(false);
    const [userAction, setUserAction] = useState<
        UserAction.POST_LOAD | UserAction.POST_LORRY | UserAction.LOAD_BID | UserAction.LORRY_BID
    >(UserAction.POST_LOAD);

    const resetBidding = () => {
        dispatchBid({ type: BID_ACTIONS.LORRY, payload: { open: false } });
        dispatchBid({ type: BID_ACTIONS.LOAD, payload: { open: false } });
        const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];
        if (loadLorryId) {
            delete router.query["lorrybid"];
            delete router.query["loadbid"];
            delete router.query["oppcmpid"];
            delete router.query["utm_medium"];
            delete router.query["utm_source"];
            delete router.query["_branch_match_id"];
            delete router.query["_branch_referrer"];
            delete router.query["r"];
            delete router.query["u_i"];
            delete router.query["utm_campaign"];
            router.replace(router, { query: "" }, { shallow: true, scroll: false });
        }
    };

    useUserActionValidationService(
        {
            onSuccess: (res) => {
                if (enable)
                    switch (res?.data.action_required) {
                        case UserActionRequiredResponse.CLEAR_CASHBACK_COINS:
                            setShowClearDuePopup?.({ isOpen: true, dueAmount: res.data.cashback_balance });
                            break;
                        case UserActionRequiredResponse.VERIFY_AADHAAR:
                            setShowMandateKycPopup?.(true);
                            break;
                        case UserActionRequiredResponse.VERIFY_PAN:
                            setShowMandateKycPopup?.(true);
                            break;
                        case UserActionRequiredResponse.VERIFY_GST:
                            setShowGstVerificationPopup?.(true);
                            break;
                        case UserActionRequiredResponse.BUY_MEMBERSHIP:
                        case UserActionRequiredResponse.UPGRADE_MEMBERSHIP:
                        case UserActionRequiredResponse.TOP_UP_MEMBERSHIP:
                        case UserActionRequiredResponse.MEMBERSHIP_EXPIRED:
                        case UserActionRequiredResponse.DAILY_MEMBERSHIP_LIMIT_EXHAUSTED:
                            // case UserActionRequiredResponse.EDIT_COMPANY_NAME:

                            setShowUpgradeMembershipPlanPopup?.(true, userAction, res?.data.action_required);
                            setMandateUserVerificationActionType?.("");
                            setMandateUserVerificationActionTriggered?.(false);
                            setMandateUserVerificationCallBackFunction?.(undefined);
                            resetBidding();
                            break;

                        default:
                            if (res?.data.can_continue) {
                                mandateUserVerificationCallBackFunction?.();
                            }
                            break;
                    }
                setEnable(false);
            },
            onError: (err) => {
                if (enable) {
                    console.log("Error ", err);
                    setEnable(false);
                    setMandateUserVerificationActionType?.("");
                    setMandateUserVerificationActionTriggered?.(false);
                    setMandateUserVerificationCallBackFunction?.(undefined);
                    resetBidding();
                    toast.error("Some thing went wrong, please try again later.");
                }
            },
            enabled: enable,
            retry: 0
        },
        {
            companyId: Number(customerCompanyId) || Number(companyId),
            action: userAction
        }
    );

    const loginCheck = (
        actionType?: UserAction.POST_LOAD | UserAction.POST_LORRY | UserAction.LOAD_BID | UserAction.LORRY_BID,
        cb?: () => void
    ) => {
        if (!companyId || !isPhoneVerified) {
            router.push(
                {
                    hash: "Login"
                },
                undefined,
                { shallow: true }
            );

            if (actionType && cb) {
                setMandateUserVerificationActionType?.(actionType);
                setUserAction(actionType);
                setMandateUserVerificationActionTriggered?.(true);
                setMandateUserVerificationCallBackFunction?.(cb);
            }

            return false;
        } else return true;
    };

    const ValidateLoadPosting = (cb?: () => void, skipLoginCheck?: boolean) => {
        /**
         * Base case
         */
        if (!skipLoginCheck) {
            if (!loginCheck(UserAction.POST_LOAD, cb)) return;
        }

        /**
         * V2
         * This will refetch the c/status api
         *
         * setEnable is used instead of refetchUserActionValidationService, because of state issue
         * updated userAction state is not passed in payload as well as for upgrade modal
         */
        setUserAction(UserAction.POST_LOAD);
        // refetchUserActionValidationService();
        setMandateUserVerificationActionTriggered?.(true);
        setEnable(true);
        setMandateUserVerificationCallBackFunction?.(cb);
    };

    const ValidateLorryPosting = (cb?: () => void, skipLoginCheck?: boolean) => {
        /**
         * Base case
         */
        if (!skipLoginCheck) {
            if (!loginCheck(UserAction.POST_LORRY, cb)) return;
        }
        //No validation for lorry posting form
        return cb?.();

        /**
         * V2
         * This will refetch the c/status api
         */
        setUserAction(UserAction.POST_LORRY);
        // refetchUserActionValidationService();
        setMandateUserVerificationActionTriggered?.(true);
        setEnable(true);
        setMandateUserVerificationCallBackFunction?.(cb);
    };

    const ValidateLorryBidding = (cb?: () => void, skipLoginCheck?: boolean) => {
        /**
         * Base case
         */
        if (!skipLoginCheck) {
            if (!loginCheck(UserAction.LORRY_BID, cb)) return;
        }
        /**
         * V2
         * This will refetch the c/status api
         */
        setUserAction(UserAction.LORRY_BID);
        setEnable(true);
        setMandateUserVerificationCallBackFunction?.(cb);
    };

    const ValidateLoadBidding = (cb?: () => void, skipLoginCheck?: boolean) => {
        /**
         * Base case
         */
        if (!skipLoginCheck) {
            if (!loginCheck(UserAction.LOAD_BID, cb)) return;
        }
        /**
         * V2
         * This will refetch the c/status api
         */
        setUserAction(UserAction.LOAD_BID);
        setEnable(true);
        setMandateUserVerificationCallBackFunction?.(cb);
    };

    const verifyKycNow = (type: "Aadhaar" | "PAN" | "GST", cb?: () => void) => {
        /**
         * Base case
         */
        if (!loginCheck()) return;
        setMandateUserVerificationCallBackFunction?.(cb);
        if (type === "GST") {
            setShowGstVerificationPopup?.(true);
            return;
        }
        if (type === "Aadhaar" || type === "PAN") {
            setShowMandateKycPopup?.(true);
            return;
        }

        cb?.();
        setShowMandateKycPopup?.(false);
        setShowGstVerificationPopup?.(false);
        router.push({ ...router, hash: undefined });
        setMandateUserVerificationCallBackFunction?.(undefined);
    };

    return { ValidateLoadPosting, ValidateLorryPosting, ValidateLorryBidding, ValidateLoadBidding, verifyKycNow };
};

export default useValidateUserStatus;
